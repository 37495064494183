import Vue from 'vue';
import Inject from '@/modules/shared/domain/di/inject';
import TYPES from '@/types';
import { parseCurrencyToNumber } from '@/vue-app/utils/parse-currency-to-number';

// Application
import UpdatePlanRetirementFundInvestorGoalCommand
  from '@/modules/flagship/retirement-investor-goal/application/commands/update-plan-retirement-fund-investor-goal-command';
import {
  SearchAllianzRecurringContributionsQuery,
} from '@/modules/my-investment/allianz/recurring-contributions/application/queries';
import EditRetirementFundGoalPlanRetirementAgeService
  from '@/modules/flagship/edit-plan-goals/edit-retirement-fund-goal-plan/application/services/edit-retirement-fund-goal-plan-retirement-age-service';
import EditRetirementFundGoalPlanCurrentAmountService
  from '@/modules/flagship/edit-plan-goals/edit-retirement-fund-goal-plan/application/services/edit-retirement-fund-goal-plan-current-amount-service';
import EditRetirementFundGoalPlanCurrentPlanService
  from '@/modules/flagship/edit-plan-goals/edit-retirement-fund-goal-plan/application/services/edit-retirement-fund-goal-plan-current-plan-service';
import EditRetirementFundGoalPlanDefineByService
  from '@/modules/flagship/edit-plan-goals/edit-retirement-fund-goal-plan/application/services/edit-retirement-fund-goal-plan-define-by-service';

// Domain
import {
  InvestorGoalContributionDto,
} from '@/modules/flagship/custom-investor-goal/domain/dtos/update-plan-custom-goal-dto';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class EditRetirementFundGoalPlanDialogViewModel {
  @Inject(TYPES.UPDATE_PLAN_RETIREMENT_FUND_INVESTOR_GOAL_COMMAND)
  private readonly update_plan_retirement_fund_command!:
    UpdatePlanRetirementFundInvestorGoalCommand;

  @Inject(TYPES.GET_ALLIANZ_RECURRING_CONTRIBUTIONS_QUERY)
  private readonly get_allianz_recurring_contributions_query!:
    SearchAllianzRecurringContributionsQuery;

  @Inject(TYPES.EDIT_RETIREMENT_FUND_GOAL_PLAN_RETIREMENT_AGE_SERVICE)
  private readonly retirement_age_service!: EditRetirementFundGoalPlanRetirementAgeService;

  @Inject(TYPES.EDIT_RETIREMENT_FUND_GOAL_PLAN_CURRENT_AMOUNT_SERVICE)
  private readonly current_amount_service!: EditRetirementFundGoalPlanCurrentAmountService;

  @Inject(TYPES.EDIT_RETIREMENT_FUND_GOAL_PLAN_CURRENT_PLAN_SERVICE)
  private readonly current_plan_service!: EditRetirementFundGoalPlanCurrentPlanService;

  @Inject(TYPES.EDIT_RETIREMENT_FUND_GOAL_PLAN_DEFINE_BY_SERVICE)
  private readonly define_by_service!: EditRetirementFundGoalPlanDefineByService;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  private readonly view: Vue;

  readonly STEPS = [
    {
      step: 0,
      component: 'EditRetirementFundCurrentPlan',
    },
    {
      step: 1,
      component: 'EditRetirementFundYourCurrentAmount',
    },
    {
      step: 2,
      component: 'EditRetirementFundRetirementAge',
    },
    {
      step: 3,
      component: 'EditRetirementFundDefineBy',
    },
    {
      step: 4,
      component: 'EditRetirementFundNewFund',
    },
    {
      step: 5,
      component: 'EditRetirementFundGoalSecondContribution',
    },
    {
      step: 6,
      component: 'EditRetirementFundSuccess',
    },
  ];

  readonly i18n_namespace = 'components.goals-dashboard.edit-plan-goals.edit-retirement-fund-goal-plan.new_fund';

  current_step = 0;

  final_step = this.STEPS.length - 1;

  current_component = this.STEPS[this.current_step];

  investor_goal_id = '';

  is_loading = false;

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  getRetirementAgeInformation = () => (this.retirement_age_service.getRetirementAgeInformation());

  getCurrentPlanInformation = () => (this.current_plan_service.getCurrentPlanInformation());

  getCurrentAmountInformation = () => (this.current_amount_service.getCurrentAmountInformation());

  getDefineByInformation = () => (this.define_by_service.getDefineByInformation());

  nextStep = () => {
    if (this.current_step === this.final_step) {
      this.endProcess();
      return;
    }

    if (this.current_step < this.final_step) {
      this.current_step += 1;
      this.current_component = this.STEPS[this.current_step];
    }
  }

  prevStep = () => {
    if (this.current_step === 0) {
      this.endProcess();
      this.view.$emit('closeModal');
      return;
    }

    if (this.current_step > 0) {
      this.current_step -= 1;
      this.current_component = this.STEPS[this.current_step];
    }
  }

  endProcess = () => {
    this.current_step = 0;
    this.current_component = this.STEPS[this.current_step];
  }

  loadRecurrentContributionInformation = async () => {
    await this.get_allianz_recurring_contributions_query.execute();
  }

  updateNewRetirementFund = async (
    contributions: Array<InvestorGoalContributionDto>,
  ) => {
    try {
      this.is_loading = true;
      const current_plan = this.getCurrentPlanInformation();
      const current_amount = this.getCurrentAmountInformation();
      const define_by = this.getDefineByInformation();
      const retirement_age = this.getRetirementAgeInformation();
      const amount_to_reach_goal = (define_by.is_defined_by_contribution) ? define_by
        .by_contribution.contribution : define_by.by_pension.you_must_save;
      const retirement_range_adjusted = (define_by.is_defined_by_contribution) ? define_by
        .by_contribution.retirement_range_adjusted : define_by.by_pension.retirement_range_adjusted;
      let initial_amount = current_amount.additional_amount;
      let initial_amount_adjusted = parseCurrencyToNumber(current_plan.current_amount);
      if (current_amount.there_is_more_savings === 'yes') {
        initial_amount_adjusted += current_amount.amount_added;
      } else initial_amount = 0;
      const new_target_amount = (define_by.is_defined_by_contribution) ? define_by.by_contribution
        .accumulated_amount : define_by.by_pension.accumulated_amount;
      const update_plan_dto = {
        retirement_fund_investor_goal: current_plan.retirement_fund_id,
        defined_by: (define_by.is_defined_by_contribution) ? 'contribution' : 'pension',
        issued_age: retirement_age.current_age,
        retirement_age_adjusted: retirement_age.retirement_age,
        retirement_range_adjusted,
        pension_range_adjusted: define_by.pension_range_adjusted,
        monthly_pension_adjusted: (define_by.is_defined_by_contribution) ? define_by.by_contribution
          .you_must_save : define_by.by_pension.contribution,
        modify_recurring_contribution_automatically: define_by
          .modify_recurring_contribution_automatically,
        go_back_to_one_contribution: define_by.go_back_to_one_contribution,
        investor_goal: {
          initial_amount,
          initial_amount_adjusted,
          accumulated_amount: new_target_amount,
          monthly_required_amount: amount_to_reach_goal,
          final_investment_date: define_by.final_investment_date,
          initial_investment_date: define_by.initial_investment_date,
        },
        contributions,
      };
      await this.update_plan_retirement_fund_command.execute(update_plan_dto);
      this.current_step = this.STEPS.length - 1;
      this.current_component = this.STEPS[this.current_step];
      this.is_loading = false;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.update_plan_retirement_fund_command'));
    }
  }

  initialize = async (investor_goal_id: string) => {
    this.investor_goal_id = investor_goal_id;
    await this.loadRecurrentContributionInformation();
  }
}
